<template>
  <div class="about">
    <el-form ref="form">
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-upload"
          style="position: relative"
        >
          Excel上传
          <input
            type="file"
            accept=".xls,.xlsx"
            class="upload-file"
            @change="Excel($event)"
          />
        </el-button>

        <el-button icon="el-icon-edit" v-if="fill" @click="exportEvent"
          >导出数据.xls</el-button
        >
      </el-form-item>
      <!--      <el-form-item>
        <el-input-number v-model="span" :min="1" :max="365"></el-input-number>
      </el-form-item>-->
    </el-form>
    <div v-if="fill">
      <vxe-table
        show-header-overflow
        border="inner"
        show-overflow
        height="400"
        :data="tableData"
        ref="xGrid2"
        highlight-hover-row
        highlight-current-row
        highlight-hover-column
        highlight-current-column
      >
        <template v-for="(item, index) in tempalteObject">
          <vxe-table-column
            :key="index"
            :field="index"
            :title="index + getWeek(index)"
            width="150"
            v-if="index === '料号'"
            fixed="left"
          ></vxe-table-column>
          <vxe-table-column
            v-else
            :key="index"
            :field="index"
            :title="index + getWeek(index)"
            width="150"
          ></vxe-table-column>
        </template>
      </vxe-table>
    </div>
    <table
      border="1"
      cellspacing="0"
      cellpadding="0"
      id="tableBox"
      style="display: none"
    >
      <tbody>
        <tr>
          <td v-for="(item, index) in tempalteObject" :key="index">
            {{ index }}
          </td>
        </tr>
        <tr v-for="(item, index) in tableData" :key="index">
          <template v-for="(item2, index2) in tempalteObject">
            <td :key="index2">
              {{ item[index2] }}
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import XLSX from "xlsx";
import dayjs from "dayjs";
export default {
  name: "Home",
  data() {
    return {
      type: "day",
      time: "",
      lists: {},
      fill: false,
      span: 60,
      tableData: [],
      tempalteObject: {},
      tempArray: [],
      ws: [],
      firstDay: "",
      upload_file: "",
    };
  },
  mounted() {
    this.time = dayjs().add(60, "day").format("YYYY/M/D");
  },
  methods: {
    getStamp(x, y) {
      return new Date(x) - new Date(y);
    },
    getWeek(d) {
      if (!isNaN(d) || isNaN(Date.parse(d))) {
        return "";
      }
      const weekday = dayjs(d).day();
      const weekMapping = [
        "周日",
        "周一",
        "周二",
        "周三",
        "周四",
        "周五",
        "周六",
      ];
      return "(" + weekMapping[weekday] + ")";
    },
    getDay(d) {
      return (
        Math.ceil(
          (new Date(d) - new Date(new Date(d).getFullYear().toString())) /
            (24 * 60 * 60 * 1000)
        ) + 1
      );
    },
    generatorData() {
      this.tempalteObject = this.setTempObject(this.ws);
      this.ws.forEach((item) => {
        this.lists[item["料号"]] = JSON.parse(
          JSON.stringify(this.tempalteObject)
        );
        for (const itemKey in item) {
          if (isNaN(itemKey) && !isNaN(Date.parse(itemKey))) {
            if (dayjs(itemKey).diff(this.firstDay, "day") < 60) {
              this.lists[item["料号"]][itemKey] += item[itemKey];
            } else {
              if (dayjs(itemKey).day() !== 1) {
                const weekday = dayjs(itemKey)
                  .add(8 - (dayjs(itemKey).day() || 7), "day")
                  .format("YYYY/M/D");
                this.lists[item["料号"]][weekday] += item[itemKey];
              } else {
                this.lists[item["料号"]][itemKey] += item[itemKey];
              }
            }
          } else {
            //普通列
            this.lists[item["料号"]][itemKey] = item[itemKey];
            /*const weekday = dayjs(itemKey)
              .add(8 - (dayjs(itemKey).day() || 7), "day")
              .format("YYYY/M/D");

            this.lists[item["料名称"]][weekday] += item[itemKey];*/
          }
        }
        this.tableData.push(this.lists[item["料号"]]);
      });
      // 调用方法将lists数组发送给后端
      this.submit_form();
    },
    setTempObject(ws) {
      const dataSet = new Set();
      const columList = new Set();
      ws.forEach((item) => {
        for (const itemKey in item) {
          if (isNaN(itemKey) && !isNaN(Date.parse(itemKey))) {
            dataSet.add(dayjs(itemKey).format("YYYY/M/D"));
          } else {
            columList.add(itemKey);
          }
        }
      });

      const dataTemp = [...dataSet].sort(this.getStamp);
      const firstDay = dataTemp[0];
      this.firstDay = firstDay;
      const lastDay = dataTemp[dataTemp.length - 1];

      const daydiff = dayjs(lastDay).diff(firstDay, "day");
      const dataList = [];
      for (let i = 0; i < daydiff + 1; i++) {
        if (i < 60) {
          dataList.push(dayjs(firstDay).add(i, "day").format("YYYY/M/D"));
        } else if (dayjs(firstDay).add(i, "day").day() === 1) {
          dataList.push(dayjs(firstDay).add(i, "day").format("YYYY/M/D"));
        }
      }
      if (daydiff > 60) {
        const weekday = dayjs(lastDay).add(1, "week").day(1).format("YYYY/M/D");
        dataList.push(weekday);
      }
      const dataObject = {};

      columList.forEach((v) => {
        dataObject[v] = "";
      });

      dataList.forEach((v) => {
        dataObject[v] = 0;
      });
      return dataObject;
    },
    exportEvent() {
      const workBook = XLSX.utils.table_to_book(
        document.getElementById("tableBox")
      );
      XLSX.writeFile(workBook, "导出_" + this.upload_file);
    },
    Excel(e) {
      let that = this;
      // 错误情况判断
      const files = e.target.files;
      if (files.length <= 0) {
        return false;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$message({
          message: "上传格式不正确，请上传xls或者xlsx格式",
          type: "warning",
        });
        return false;
      } else {
        console.log(files[0]);
        that.upload_file = files[0].name;
      }
      // 读取表格
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const workbook = XLSX.read(data, {
            type: "binary",
          });
          // 读取第一张表
          const wsname = workbook.SheetNames[0];
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]);
          // 打印 ws 就可以看到读取出的表格数据
          this.ws = ws;
          this.tableData = [];
          this.tempalteObject = {};
          this.tempArray = [];
          this.lists = [];
          this.firstDay = "";
          this.generatorData(ws);
        } catch (e) {
          return false;
        }
      };
      fileReader.readAsBinaryString(files[0]);
    },
    submit_form() {
      this.fill = true;
      // 在这里发送数据
    },
  },
};
</script>
<style>
.about {
  padding: 8px;
  box-sizing: border-box;
}

.upload-file {
  font-size: 20px;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  filter: alpha(opacity=0);
  width: 100%;
}
</style>
