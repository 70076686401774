<template>
  <div class="container" :class="bg">
    <el-row>
      <el-button type="success" @click="bg = 'EXCEL'">EXCEL</el-button>
      <el-button type="primary" @click="bg = 'WORD'">WORD</el-button>
      <el-button type="warning" @click="bg = 'PPT'">PPT</el-button>
    </el-row>
    <div class="max-box">
      <span class="max-text" @click="link">{{ bg }}</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      bg: "EXCEL",
    };
  },
  methods: {
    link() {
      if (this.bg !== "EXCEL") {
        return this.$notify.info({
          title: "建设中",
          message: "敬请期待",
        });
      }
      this.$router.push(this.bg);
    },
  },
};
</script>
<style scoped>
.container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  padding: 8px;
  box-sizing: border-box;
}
.max-box {
}
.max-text {
  color: white;
  font-size: 130px;
  font-weight: bold;
  letter-spacing: 4px;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s linear;
  cursor: pointer;
}
.max-text:hover {
  transform: translate(-50%, -50%) scale(1.1);
}
.EXCEL {
  background-color: #217346;
}
.WORD {
  background-color: #2c5899;
}
.PPT {
  background-color: #b6472a;
}
</style>
